@font-face {
  font-family: Regular;
  src: url(/fonts/aktivgrotesk-regular.woff);
}

@font-face {
  font-family: Bold;
  src: url(/fonts/aktivgrotesk-bold.woff);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  text-decoration: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, a:visited, a:active {
  text-decoration: none;
  color: inherit;
}