@font-face {
  font-family: Regular;
  src: url(/fonts/aktivgrotesk-regular.woff);
}

@font-face {
  font-family: Bold;
  src: url(/fonts/aktivgrotesk-bold.woff);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  text-decoration: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, a:visited, a:active {
  text-decoration: none;
  color: inherit;
}
.App {
    text-align: center;
}

.App-logo {
    height:10vh;
    pointer-events: none;
}

.App-header {
    background-color: #000;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 0 10%;
}

.title {
    text-align: left;
    font-size: 64px;
    font-family: 'Bold', sans-serif;
    margin-bottom: 0;
}

.subTitle {
    text-align: left;
    font-size: 24px;
    font-family: 'Regular', sans-serif;
    color: gray;
}

.subTitle :hover {
    opacity: 0.6;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
    to {
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
    to {
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }
}

