.App {
    text-align: center;
}

.App-logo {
    height:10vh;
    pointer-events: none;
}

.App-header {
    background-color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 0 10%;
}

.title {
    text-align: left;
    font-size: 64px;
    font-family: 'Bold', sans-serif;
    margin-bottom: 0;
}

.subTitle {
    text-align: left;
    font-size: 24px;
    font-family: 'Regular', sans-serif;
    color: gray;
}

.subTitle :hover {
    opacity: 0.6;
}

@keyframes App-logo-spin {
    from {
        transform: rotateY(0);
    }
    to {
        transform: rotateY(360deg);
    }
}
